<template>
	<layouts-minimum>
		<div class="py-10">
			<div class="pb-5">
				<h3 class="ls-tight fw-bolder h3">Mis pedidos</h3>
				<p class="text-sm text-muted">El listado muestra tus últimos pedidos.</p>
			</div>
			<div v-if="loading" class="list-group list-group-flush">
				<orders-cardskeletor v-for="i in 3" v-bind:key="i" class="list-group-item px-0" />
			</div>
			<div v-if="!loading && orders.length > 0" class="list-group list-group-flush">
				<orders-card v-for="order in orders" v-bind:key="order.id" :order="order" class="list-group-item px-0" />
			</div>
			<div v-if="!loading && orders.length == 0">
				<common-message-error text="No se encontraron pedidos anteriores." />
			</div>
		</div>
	</layouts-minimum>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import OrdersRepository from '@/repositories/OrdersRepository';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { catchError } = composableForm();
			const orders = ref(null);
			const loading = ref(true);

			const getOrders = async () => {
				OrdersRepository.list().then((response) => {
					loading.value = false;
					orders.value = response.data;
				}).catch((e) => {
					loading.value = false;
					catchError(e);
				});
			}

			onMounted(getOrders);

			return { loading, orders };
		}
	}
</script>